import { styled } from '@mui/material/styles';

import publicPath from '@/utils/functions/publicPath';

const NotFoundWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  backgroundImage: `url(${publicPath('/images/404-bg.png')})`,
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default NotFoundWrapper;
